@import url(https://fonts.googleapis.com/css?family=Titillium+Web:400,600,700);
:root {
  --toastify-font-family: "Titillium Web", sans-serif;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/************ Rotates ****************/
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
.spinner {
  height: 500px;
  width: 100%;
  position: relative;
}
.spinner img {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 50px;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.spinner .rotating {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.confirm {
  background: #3a494c;
  width: 400px;
  height: 150px;
  padding: 20px;
  text-align: center;
  color: #ffffff;
  z-index: 100;
  position: fixed;
  top: 30%;
  left: 50%;
}

.social-link.yt {
  margin-right: 20px;
}
.social-link.yt a {
  width: 20px;
  height: 14px;
  background: url("./assets/yt.png");
}

.social-link.inst a {
  width: 14px;
  height: 14px;
  background: url("./assets/inst.png");
}

.api-key{
  font-size: 15px;
}

.ticket-form{
  margin-bottom: 20px;
}
.contact-form{
  width:50%;
  margin:auto;
  position:relative;
  right:0;
  left:0;
}
.pp-consent{
  font-family: "Titillium Web", serif;
  background-color: rgb(82,175,222) !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 700px;
  color: #fff;
  height: 800px;
  z-index:99999999999;
  padding: 20px;
}
.pp-consent h3{
  color:#fff;
}

@media only screen and (max-width: 700px) {
  .api-key{
    font-size: 8px;
  }
  .contact-form{
    width: 100%;
  }
  .pp-consent{
    width: 80%;
    height: 700px;
    font-size: 11px;
  }
  .pp-consent h2{
    font-size: 13px;
  }
}